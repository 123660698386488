.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
}

.popup-frame {
  background: #ECF9FF;
  padding: 30px;
  border-radius: 15px;
  position: relative;
  max-width: 1000px;
  width: 95%;
  height: auto;
  text-align: left;
  overflow: hidden;
}

.popup-frame h1 {
  margin-top: 0;
}

.popup-frame p {
  width: 80%;
}

.popup-frame input {
  margin: 5px 0;
  padding: 15px;
  width: 50%;
  border: 0px solid #ccc;
  border-radius: 10px;
}

.popup-frame button {
  margin-top: 1rem;
  background-color: rgba(6, 6, 64, 1);
  color: #fff;
  padding: 11px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 6rem;
  margin-left: 1rem;
  transition: background-color 0.3s;
}

.close-button-news {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  cursor: pointer;
}

.popup-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-content-news {
  width: 70%;
  margin-left: 2.5rem;
}

.right-content-news {
  width: 60%;
}

.right-content-news p {
  width: 100%;
}

.right-content-news h2 {
  margin-top: 1rem;
}

@media screen and (max-width: 640px) {
  .popup-frame {
    padding: 10px;
    width: 95%;
    text-align: center;
  }
  
  .popup-frame h1 {
    margin-top: 1rem;
  }
  
  .popup-frame p {
    width: 100%;
    margin: 0;
  }
  
  .popup-frame input {
    width: 85%;
    margin: 1.5rem auto;
  }
  
  .popup-frame button {
    width: 5rem;
    margin: 0 auto;
  }
  
  .popup-content {
    display: block;
  }
  
  .main-content-news {
    width: 100%;
    margin-left: 0;
  }
  
  .right-content-news {
    width: 100%;
  }
  
  .right-content-news p {
    width: 90%;
    margin: 1rem auto;
  }
  .right-content-news h2 {
    margin-top: 1rem;
  }
}
