.screen {
  max-width: 1433px;
  margin: 0 auto;
}

.meistä {
  background-color: #ECF9FF;
  padding-top: 9rem;
  height: 43rem;
}

.meistä-main {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  max-width: 1439px;
  width: 100%;
  margin: 0 auto;
}

.meistä-main h1 {
  font-size: 2.8rem;
  color: rgba(6, 6, 64, 1);
  margin-bottom: 1.9rem;
}

.meistä-main h1::after {
  content: "";
  display: block;
  border-bottom: 5px solid white;
  width: 86%;
  position: absolute;
  left: 7%;
  border-radius: 15px; 
}

.meistä-main p {
  font-size: 17px;
  margin-left: 5rem;
  margin-right: 5rem;
  color: rgba(130, 130, 151, 1);
}

.meistä-main h1,
.meistä-main p {
    opacity: 5;
}

.meistä-pic-frame {
  margin-top: 2rem;
  margin-left: 6rem;
  margin-right: 6rem;
  border-radius: 15px;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}

.meistä-pic-frame::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ECF9FF;
  opacity: 0.9;
  border-radius: 15px;
}

.myMeistä-frame {
  background-color: #ECF9FF;
  height: auto;
}

.myMeistä {
  margin: 0 120px;
}

.myMeistä h1 {
  text-align: center;
  font-size: 2.6rem;
  margin-top: 0;
  padding-top: 6rem;
  color: rgba(6, 6, 64, 1);
  margin-bottom: 0.3rem;
}

.underline {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.myMeistä-text1-container {
  position: relative;
  display: flex;
  align-items: center;
  margin: 30px 90px;
  height: 30rem;
}

.myMeistä-text1 h2 {
  margin-bottom: 0.5rem;
  font-size: 1.9rem;
  color: rgba(6, 6, 64, 1);
}

.myMeistä-text1 p {
  margin-top: 0;
  font-size: 1.2rem;
  width: 80%;
  color: rgba(130, 130, 151, 1);
}

.box1 {
  width: 40rem;
  height: 20rem;
}

.myMeistä-text3-container {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 90px;
  height: 30rem;
}

.myMeistä-text3 h2 {
  margin-bottom: 0.5rem;
  font-size: 1.9rem;
  color: rgba(6, 6, 64, 1);
}

.myMeistä-text3 p {
  margin-top: 0;
  font-size: 1.2rem;
  width: 80%;
  color: rgba(130, 130, 151, 1);
}

.box3 {
  width: 40rem;
  height: 20rem;
}

.myMeistä-text2-container {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 90px;
  height: auto;
}

.myMeistä-text2 {
  margin-left: 7rem;
}

.myMeistä-text2 h2 {
  margin-bottom: 0.5rem;
  font-size: 1.9rem;
  color: rgba(6, 6, 64, 1);
}

.myMeistä-text2 p {
  margin-top: 0;
  font-size: 1.2rem;
  width: 100%;
  color: rgba(130, 130, 151, 1);
}

.box2 {
  width: 40rem;
  height: 20rem;
}

.myMeistä button {
  background-color: rgba(6, 6, 64, 1);
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 7rem;
  max-width: 7rem;
}

.overlay-meistä {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1300;
}

.form-container-meistä {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 80%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  z-index: 1350;
  border-radius: 15px;
}

.x-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 35px;
  cursor: pointer;
}

.myFormeistä {
  margin-top: 1rem;
  padding: 1rem;
}

.meistä-wrapper h2 {
  text-align: center;
  font-size: 24px;
  margin: 10px 0;
  color: rgba(6, 6, 64, 1);
}

.myFormeistä p {
  margin-top: 1rem;
  font-size: 16px;
  color: #555;
  width: 100%;
}

.meistä-content {
  margin-top: 2rem;
}

@media screen and (max-width: 600px) {
  .meistä {
    padding-top: 7rem;
    height: 30rem;
    width: auto;
  }
  
  .meistä-main h1 {
    font-size: 1.5rem;
    margin-bottom: 1.3rem;
  }
  
  .meistä-main h1::after {
    width: 86%;
    position: relative;
    left: 7%;
  }
  
  .meistä-main p {
    font-size: 14px;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  
  .meistä-pic-frame {
    margin: 2rem auto;
    width: 85%;
    height: 100%;
  }
  
  .myMeistä {
    padding-top: 0;
    margin: 0 0;
  }
  
  .myMeistä h1 {
    text-align: center;
    font-size: 1.9rem;
    margin-top: 0;
    padding-top: 5rem;
    color: rgba(6, 6, 64, 1);
    margin-bottom: 0.3rem;
  }
  
  .underline {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 220px;
  }
  
  .myMeistä-text1-container {
    position: static;
    display: block;
    align-items: center;
    margin: 0 0;
    height: 31.5rem;
  }

  .myMeistä-text1 {
    text-align: center;
    margin: 0 0;
  }
  
  .myMeistä-text1 h2 {
    margin-top: 4.8rem;
    margin-bottom: 0.5rem;
    font-size: 1.6rem;
  }
  
  .myMeistä-text1 p {
    margin-top: 0;
    font-size: 1rem;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
  }
  
  .box1 {
    display: block;
    margin-top: 2rem;
    width: 70%;
    height: 14.5rem;
    max-width: 17rem;
    margin-left: auto;
    margin-right: auto;
  }

  .myMeistä-text3-container {
    display: block;
    margin: 0 0;
    height: 40rem;
    text-align: center;
  }
  
  .myMeistä-text3 h2 {
    margin-top: 12rem;
    margin-bottom: 0.5rem;
    font-size: 1.6rem;
  }
  
  .myMeistä-text3 p {
    margin-top: 0rem;
    font-size: 1rem;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
  }
  
  .box3 {
    display: block;
    margin-top: 2rem;
    width: 70%;
    height: 14.5rem;
    max-width: 17rem;
    margin-left: auto;
    margin-right: auto;
  }
  
  .myMeistä-text2-container {
    position: static;
    display: block;
    margin: 0px 0px;
    height: 10rem;
  }
  
  .myMeistä-text2 {
    text-align: center;
    margin: 0 0;
    height: 16rem;
    background-color: #ECF9FF;
  }
  
  .myMeistä-text2 h2 {
    margin-top: 0;
    color: rgba(6, 6, 64, 1);
    margin-bottom: 0.5rem;
    font-size: 1.6rem;
  }
  
  .myMeistä-text2 p {
    font-size: 1rem;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .box2 {
    display: none;
  }
  
  .myMeistä-text2 button {
    padding: 9px;
  }
  
  .form-container-meistä {
    overflow: scroll;
  }
  
  .myFormeistä {
    margin-top: 0;
    padding: 0.5rem;
  }
  
  .meistä-wrapper h2 {
    font-size: 22px;
  }
  
  .myFormeistä p {
    margin-top: 1rem;
    font-size: 15px;
  }
}

@media (min-width: 601px) and (max-width: 768px) {
  .myMeistä-frame {
    height: auto;
  }

  .meistä {
    padding-top: 7rem;
    height: 30rem;
    width: auto;
  }
  
  .meistä-main h1 {
    font-size: 1.5rem;
    margin-bottom: 1.3rem;
  }
  
  .meistä-main h1::after {
    width: 86%;
    position: relative;
    left: 7%;
  }
  
  .meistä-main p {
    font-size: 14px;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  
  .meistä-pic-frame {
    margin: 2rem auto;
    width: 85%;
    height: 100%;
  }
  
  .myMeistä {
    padding-top: 0;
    margin: 0 0;
  }
  
  .myMeistä h1 {
    text-align: center;
    font-size: 1.9rem;
    margin-top: 0;
    padding-top: 5rem;
    color: rgba(6, 6, 64, 1);
    margin-bottom: 0.3rem;
  }
  
  .underline {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 220px;
  }
  
  .myMeistä-text1-container {
    position: static;
    display: block;
    align-items: center;
    margin: 0 0;
    height: 27rem;
  }

  .myMeistä-text1 {
    text-align: center;
    margin: 0 0;
  }
  
  .myMeistä-text1 h2 {
    margin-top: 4.8rem;
    color: rgba(6, 6, 64, 1);
    margin-bottom: 0.5rem;
    font-size: 1.6rem;
  }
  
  .myMeistä-text1 p {
    margin-top: 0;
    font-size: 1rem;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
  }
  
  .box1 {
    display: block;
    margin-top: 2rem;
    width: 45%;
    height: 14.5rem;
    max-width: 17rem;
    margin-left: auto;
    margin-right: auto;
  }

  .myMeistä-text3-container {
    display: block;
    margin: 0 0;
    height: 33rem;
    text-align: center;
  }
  
  .myMeistä-text3 h2 {
    margin-top: 6.5rem;
    margin-bottom: 0.5rem;
    font-size: 1.6rem;
  }
  
  .myMeistä-text3 p {
    margin-top: 0;
    font-size: 1rem;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
  }
  
  .box3 {
    display: block;
    margin-top: 2rem;
    width: 70%;
    height: 14.5rem;
    max-width: 17rem;
    margin-left: auto;
    margin-right: auto;
  }
  
  .myMeistä-text2-container {
    position: static;
    display: block;
    margin: 0 0;
    height: 10rem;
  }
  
  .myMeistä-text2 {
    text-align: center;
    margin: 0 0;
    height: 16rem;
    background-color: #ECF9FF;
  }
  
  .myMeistä-text2 h2 {
    margin-top: 1rem;
    color: rgba(6, 6, 64, 1);
    margin-bottom: 0.5rem;
    font-size: 1.6rem;
  }
  
  .myMeistä-text2 p {
    font-size: 1rem;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .box2 {
    display: none;
  }
  
  .myMeistä-text2 button {
    padding: 9px;
  }
  
  .form-container-meistä {
    overflow: scroll;
  }
  
  .myFormeistä {
    margin-top: 0;
    padding: 0.5rem;
  }
  
  .meistä-wrapper h2 {
    font-size: 22px;
  }
  
  .myFormeistä p {
    margin-top: 1rem;
    font-size: 15px;
  }
}

@media (min-width: 768px) and (max-width: 1230px) {
  .myMeistä-frame {
    height: auto;
  }

  .meistä {
    padding-top: 7rem;
    height: 30rem;
    width: auto;
  }
  
  .meistä-main h1 {
    font-size: 1.5rem;
    margin-bottom: 1.3rem;
  }
  
  .meistä-main h1::after {
    width: 86%;
    position: relative;
    left: 7%;
  }
  
  .meistä-main p {
    font-size: 14px;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  
  .meistä-pic-frame {
    margin: 2.5rem auto;
    width: 85%;
    height: 100%;
  }
  
  .myMeistä {
    padding-top: 0;
    margin: 0 0;
  }
  
  .myMeistä h1 {
    text-align: center;
    font-size: 1.9rem;
    margin-top: 0;
    padding-top: 5rem;
    color: rgba(6, 6, 64, 1);
    margin-bottom: 0.3rem;
  }
  
  .underline {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 220px;
  }
  
  .myMeistä-text1-container {
    position: static;
    display: block;
    align-items: center;
    margin: 0 0;
    height: 27rem;
  }

  .myMeistä-text1 {
    text-align: center;
    margin: 0 0;
  }
  
  .myMeistä-text1 h2 {
    margin-top: 4.8rem;
    color: rgba(6, 6, 64, 1);
    margin-bottom: 0.5rem;
    font-size: 1.6rem;
  }
  
  .myMeistä-text1 p {
    margin-top: 0;
    font-size: 1rem;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
  }
  
  .box1 {
    display: block;
    margin-top: 2rem;
    width: 45%;
    height: 14.5rem;
    max-width: 17rem;
    margin-left: auto;
    margin-right: auto;
  }

  .myMeistä-text3-container {
    display: block;
    margin: 0 0;
    height: 33rem;
    text-align: center;
  }
  
  .myMeistä-text3 h2 {
    margin-top: 5rem;
    margin-bottom: 0.5rem;
    font-size: 1.6rem;
  }
  
  .myMeistä-text3 p {
    margin-top: 0rem;
    font-size: 1rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  
  .box3 {
    display: block;
    margin-top: 2rem;
    width: 70%;
    height: 14.5rem;
    max-width: 17rem;
    margin-left: auto;
    margin-right: auto;
  }
  
  .myMeistä-text2-container {
    position: static;
    display: block;
    margin: 0 0;
    height: 10rem;
  }
  
  .myMeistä-text2 {
    text-align: center;
    margin: 0 0;
    height: 16rem;
    background-color: #ECF9FF;
  }
  
  .myMeistä-text2 h2 {
    margin-top: 0;
    color: rgba(6, 6, 64, 1);
    margin-bottom: 0.5rem;
    font-size: 1.6rem;
  }
  
  .myMeistä-text2 p {
    font-size: 1rem;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .box2 {
    display: none;
  }
  
  .myMeistä-text2 button {
    padding: 9px;
  }
  
  .form-container-meistä {
    overflow: scroll;
  }
  
  .myFormeistä {
    margin-top: 0;
    padding: 0.5rem;
  }
  
  .meistä-wrapper h2 {
    font-size: 22px;
  }
  
  .myFormeistä p {
    margin-top: 1rem;
    font-size: 15px;
  }
}
