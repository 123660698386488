.screen {
  max-width: 1433px;
  margin: 0 auto;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 40px auto;
  max-width: 1350px;
  width: 90%;
  background-color: white;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.tuutorikeskus-logo {
  width: 250px;
  height: 90px;
}

.nav-links {
  display: flex;
}

.nav-links a {
  color: rgba(0, 0, 0, 0.73);
  text-decoration: none;
  transition: color 0.3s ease;
  border-radius: 5px;
  margin: 0 15px;
}

.nav-links a:hover {
  color: rgba(0, 229, 204, 1);
}

.contact-panel {
  display: none;
}

/* Mobile-specific styles */
@media (min-width: 360px) and (max-width: 992px) {
  .tuutorikeskus-logo {
    width: auto;
    height: 2.6rem;
  }

  .navbar {
    padding: 1.1rem;
    border-radius: 0;
    margin: 0 0;
    width: 100%;
  }

  .nav-links {
    flex-direction: column;
    text-align: left;
    position: fixed;
    left: 0;
    top: 5.1rem;
    height: 42%;
    width: 100vw;
    padding: 0;
    background-color: white;
    z-index: 1001;
    transform: translateX(-100%);
    transition: transform 0.6s ease-out;
  }

  /* When toggled, slide the nav-links into view */
  .nav-links.show {
    transform: translateX(0);
  }

  .nav-links a {
    display: block;
    margin: 0;
    padding: 0.94rem 1.5rem;
    border-radius: 0;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 0, 0.99);
  }

  .nav-links a:last-child {
    border-bottom: none;
  }

  .contact-panel {
    position: fixed;
    display: block;
    right: 0;
    top: 22.3rem;
    height: 100%;
    width: 100vw;
    padding: 1.5rem;
    background-color: #ECF9FF;
    z-index: 1001;
    transform: translateX(100%);
    transition: transform 0.6s ease-out;
  }

  .contact-panel.show {
    transform: translateX(0);
  }

  .contact-panel h3 {
    font-size: 1.05rem;
    margin-top: 0;
  }
}

.contact-panel a {
  text-decoration: none;
  color: black;
  transition: color 0.3s ease;
}

.social-icons {
  display: flex;
  gap: 20px;
  margin-top: 0.5rem;
}

/* Enlarge the social icons */
.social-icons a {
  font-size: 1.2rem;
  transition: transform 0.3s ease;
}

.social-icons a:hover {
  transform: scale(1.1);
}
