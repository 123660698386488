/* src/components/Etusivu.css */
@font-face {
  font-family: 'Zen Antique Soft';
  src: url('../font/ZenAntiqueSoft-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

.screen {
  max-width: 1439px;
  margin: 0 auto;
}

.etusivu {
  background-color: #ECF9FF;
  padding-top: 5rem;
  height: 48rem;
}

.firstpage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 120px;
  margin-right: 120px;
}

.image-container {
  flex-shrink: 0;
}

.medical-image {
  margin-top: 11rem;
  max-width: 600px;
  position: relative;
  z-index: 0;
  transition: filter 0.3s;
}

.video-container iframe{
  position: relative;
  left: 9.45rem;
  bottom: 15.2rem;
  z-index: 1;
  width: 289px;
  height: 176px;
}

.text-container-etusivu {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.text-container-etusivu h1 {
  font-size: 3rem;
  width: 80%;
  margin-bottom: 1rem;
  max-width: 450px;
  color: rgba(6, 6, 64, 1);
}

.text-container-etusivu p {
  margin-top: 0;
  width: 100%;
  max-width: 400px;
  font-size: 18px;
  color: rgba(130, 130, 151, 1);
}

.kurssit-button {
  background-color: rgba(6, 6, 64, 1);
  margin-top: 15px;
  color: white;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  max-width: 7rem;
  transition: background-color 0.3s;
}

.kurssit-button:hover {
  background-color: rgba(6, 6, 64, 0.8);
}

.frame-container {
  position: relative;
  width: 100%;
  height: 100px;
  max-width: 425px;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 28px;
  background-color: white;
  border-radius: 20px;
}

.frame-container p {
  font-size: 0.95rem;
  text-align: left;
  color: rgba(6, 6, 64, 1);
}

.frame-container::before {
  content: '\201C';
  font-size: 2.8rem;
  color: rgba(0, 229, 204, 1);
  position: absolute;
  bottom: 45px;
  left: 10px;
}

/* Palvelumme Section */
.palvelumme {
  margin: 60px 120px;
  text-align: center;
}

.palvelumme h1 {
  margin-top: 3rem;
  font-size: 2.8rem;
  color: rgba(6, 6, 64, 1);
}

.palvelumme h1::after {
  content: "";
  display: block;
  border-bottom: 5px solid #ECF9FF;
  width: 50%;
  position: absolute;
  left: 25%;
  border-radius: 15px; 
}

.icon-frame {
  background-color: rgba(236, 249, 255, 0.8);
  border-radius: 10px;
  width: 3.6rem;
  height: 3.5rem;
}

.card-logo {
  margin-top: 0.7rem;
  margin-left: 0.5rem;
  font-size: 2rem;
  color: rgba(79, 93, 115, 1);
  width: 40px;
  margin-bottom: 10px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.server-card {
  width: calc(33.33% - 20px);
  margin: 40px 10px 0;
  padding: 20px;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  text-align: left;
}

.server-card h2 {
  margin-top: 2.2rem;
  font-size: 1.5rem;
  color: rgba(6, 6, 64, 1);
}

.server-card p {
  font-size: 1.15rem;
  color: rgba(130, 130, 151, 1);
  margin-top: 0;
}

/* Aikajana Section */
.aikajana-small {
  display: none;
}

.aikajana {
  background-color: #ECF9FF;
  padding-bottom: 1rem;
}

.aikajana-container {
  text-align: center;
  margin: 60px 120px;
  padding-top: 1rem;
}

.aikajana h1 {
  font-size: 2.8rem;
  color: rgba(6, 6, 64, 1);
  margin-bottom: 2.5rem;
}

.aikajana h1::after {
  content: "";
  display: block;
  border-bottom: 5px solid white; 
  width: 50%; 
  position: absolute;
  left: 25%;
  border-radius: 15px; 
}

.aikajana p {
  font-size: 1.3rem;
  color: rgba(130, 130, 151, 1);
}

.timeline {
  display: flex;
  justify-content: center;
}

.timeline-item {
  position: relative;
  text-align: center;
  margin: 2rem auto 10px;
  width: 100%;
}

.circle {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px; 
  background-color: rgba(255, 255, 255, 1);
  border-radius: 50%;
  font-size: 1.6rem; 
}

.percentage {
  font-family: 'Zen Antique Soft', sans-serif;
  position: absolute;
  top: 49%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold; 
  color: rgb(124, 216, 255);
}

.year {
  font-family: 'Zen Antique Soft', sans-serif;
  align-items: center;
  justify-content: center;
  padding: 0 5%;
  font-size: 1.6rem;
  color: rgb(124, 216, 255);
  max-width: 100%; 
  width: 100%;
  margin: 0 auto;
  font-weight: bold;
  flex-wrap: wrap;
}

.timeline-frame {
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
  width: 100%;
  margin: 0 auto 5rem;
  padding: 5px;
  border-radius: 10px;
}

/* Lisa Tietoa Section */
.lisa-tietoa {
  text-align: center;
  margin: 60px 120px;
}

.lisa-tietoa h1 {
  font-size: 2.8rem;
  color: rgba(6, 6, 64, 1);
  margin-bottom: 1.9rem;
}

.lisa-tietoa h1::after {
  content: "";
  display: block;
  border-bottom: 5px solid #ECF9FF;
  width: 50%;
  position: absolute;
  left: 25%;
  border-radius: 15px; 
}

.info-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card-pic-meistä-small {
  width: 100%;
  height: 25.5rem;
  max-height: 27rem;
  border-radius: 15px 15px 0 0;
  mask-image: linear-gradient(to bottom, rgba(236, 249, 255, 0.9), transparent 14rem);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.card-pic-lääketiede-small {
  width: 100%;
  height: 14.5rem;
  border-radius: 15px 15px 0 0;
  mask-image: linear-gradient(to bottom, rgba(236, 249, 255, 0.9), transparent 14rem);
  position: absolute;
  top: 0;
  left: 0;
}

.info-card {
  height: 30rem;
  width: calc(33.33% - 20px);
  margin: 40px 10px;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  text-align: left;
  overflow: hidden;
  position: relative;
}

.info-card h2 {
  margin-top: 14.5rem;
  font-size: 1.5rem;
  margin-left: 1rem;
  color: rgba(6, 6, 64, 1);
}

.info-card p {
  font-size: 18px;
  color: rgba(130, 130, 151, 1);
  margin-top: 0;
  margin-left: 1rem;
}

.info-card a {
  color: rgb(124, 216, 255);
  margin-left: 1rem;
  border-bottom: 2px solid transparent;
  transition: border-color 0.3s;
  text-decoration: none;
  cursor: pointer;
  z-index: 500;
}

.info-card a:hover {
  border-color: rgb(124, 216, 255);
}


/* RESPONSIVE PHONE */
@media screen and (max-width: 600px) {
  .etusivu {
    height: 51rem;
  }

  .firstpage {
    display: block;
    margin-left: 2rem;
    margin-top: 1rem;
  }

  .medical-image {
    display: none;
  }
  
  .video-container iframe{
    bottom: 1.5rem;
    left: 0;
    position: relative;
    z-index: 0;
    width: 289px;
    height: 176px;
  }

  .text-container-etusivu h1 {
    font-size: 1.9rem;
    width: 21rem;
  }
  
  .text-container-etusivu p {
    font-size: 0.9rem;
    width: 18rem;
  }
  
  .kurssit-button {
    font-size: 0.7rem;
  }
  
  .frame-container {
    width: 17rem;
    margin-bottom: 0;
  }
  
  .frame-container p {
    font-size: 0.8rem;
    width: 15rem;
  }

  /* Palvelumme section */
  .palvelumme {
    margin: 0 0;
  }
  
  .palvelumme h1 {
    font-size: 1.9rem;
    margin-top: 1.5rem;
  }
  
  .palvelumme p {
    font-size: 0.9rem;
  }
  
  .icon-frame {
    background-color: rgba(236, 249, 255, 0.8);
    border-radius: 10px;
    width: 3rem;
    height: 3rem;
  }
  
  .card-logo {
    margin-top: 0.7rem;
    margin-left: 0.3rem;
    font-size: 1.5rem;
    color: rgba(79, 93, 115, 1);
    width: 40px;
    margin-bottom: 10px;
  }
  
  .card-container {
    display: block;
    flex-wrap: wrap;
  }

  .server-card {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .server-card h2 {
    font-size: 1.3rem;
  }

  .server-card p {
    font-size: 16px;
  }

  /* Aikajana Section */
  .aikajana {
    display: none;
  }
  /* Timeline Section */
  .aikajana-small {
    margin-top: 3rem;
    display: block;
    background-color: #ECF9FF;
    padding-bottom: 1rem;
    height: 30rem;
  }

  .aikajana-container-small {
    text-align: center;
    padding-top: 0.5rem;
  }

  .aikajana-small h1 {
    font-size: 1.9rem;
    color: black;
    margin-bottom: 1.5rem;
  }

  .aikajana-small h1::after {
    content: "";
    display: block;
    border-bottom: 5px solid white;
    width: 60%;
    position: absolute;
    left: 20%;
    border-radius: 15px;  
  }

  .aikajana-small p {
    font-size: 16px;
    color: rgba(130, 130, 151, 1);
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .timeline-small {
    display: flex;
    justify-content: center;
  }

  .timeline-item-small {
    position: relative;
    text-align: center;
    margin: 15px 0;
  }

  .circle-small {
    position: relative;
    display: inline-block;
    width: 100px;
    height: 100px; 
    background-color: rgba(255, 255, 255, 1);
    border-radius: 50%;
    font-size: 1.6rem;
  }

  .percentage-small {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold; 
    color: rgb(124, 216, 255);
  }

  .year-small {
    align-items: center;
    justify-content: center;
    padding: 0 5%;
    font-size: 1.6rem;
    color: rgb(124, 216, 255);
    max-width: 100%; 
    margin: 0 22.7px;
    font-weight: bold;
    flex-wrap: wrap;
  }

  .timeline-frame-small {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(255, 255, 255, 1);
    width: 30%;
    margin-bottom: 1rem;
    padding: 3px;
    border-radius: 10px;
  }

  .arrow-left, .arrow-right {
    font-size: 1.6rem;
    color: rgba(130, 130, 151, 1);
    cursor: not-allowed; 
    justify-content: space-between;
    margin: 0.5rem 20px;
  }

  .arrow-left:hover, .arrow-right:hover {
    color: rgba(130, 130, 151, 1);
    cursor: pointer;
  }
  
  /* Lisätietoa section */
  .lisa-tietoa {
    margin: 0px 0px;
    padding-top: 1rem;
  }

  .lisa-tietoa h1 {
    font-size: 1.9rem;
  }
  
  .info-card-container {
    display: block;
    flex-wrap: wrap;
  }
  
  .info-card {
    margin-top: 2.5rem;
    width: 90%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  
  .info-card h2 {
    font-size: 1.3rem;
  }
  
  .info-card p {
    font-size: 16px;
    margin-bottom: 0.7rem;
  }

  .card-links1 , .card-links3{
    margin-bottom: 1.5rem;
  }
  
  .card-links2 {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
  }
}
/* RESPONSIVE TABLET */
@media (min-width: 601px) and (max-width: 768px) {
.etusivu {
  padding-top: 7rem;
  height: 70rem;
}

.firstpage {
  display: block;
  margin-left: 50px;
  margin-right: 0px;
}

.medical-image {
  margin-top: 0;
  max-width: 550px;
}

.video-container iframe{
  left: 8.6rem;
  bottom: 14rem;
  width: 265px;
  height: 165px;
}

.text-container-etusivu {
  display: block;
}

.text-container-etusivu h1 {
  font-size: 2.5rem;
  width: 80%;
}

.kurssit-button {
  width: 8rem;
  max-width: 8rem;
}

/* Palvelumme Section */
.palvelumme {
  margin: 0 0;
}

.palvelumme h1::after {
  width: 56%;
  left: 22%;
}

.card-container {
  display: block;
}

.server-card {
  width: 80%;
  margin: 30px auto 0;
}

/* Aikajana Section */
.aikajana {
  display: none;
}
/* Timeline Section */
.aikajana-small {
  margin-top: 3rem;
  display: block;
  background-color: #ECF9FF;
  padding-bottom: 1rem;
  height: 30rem;
}

.aikajana-container-small {
  text-align: center;
  padding-top: 0.5rem;
}

.aikajana-small h1 {
  font-size: 1.9rem;
  color: black;
  margin-bottom: 1.5rem;
}

.aikajana-small h1::after {
  content: "";
  display: block;
  border-bottom: 5px solid white;
  width: 60%;
  position: absolute;
  left: 20%;
  border-radius: 15px;  
}

.aikajana-small p {
  font-size: 16px;
  color: rgba(130, 130, 151, 1);
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.timeline-small {
  display: flex;
  justify-content: center;
}

.timeline-item-small {
  position: relative;
  text-align: center;
  margin: 15px 0;
}

.circle-small {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px; 
  background-color: rgba(255, 255, 255, 1);
  border-radius: 50%;
  font-size: 1.6rem;
}

.percentage-small {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold; 
  color: rgb(124, 216, 255);
}

.year-small {
  align-items: center;
  justify-content: center;
  padding: 0 5%;
  font-size: 1.6rem;
  color: rgb(124, 216, 255);
  max-width: 100%; 
  margin: 0 22.7px;
  font-weight: bold;
  flex-wrap: wrap;
}

.timeline-frame-small {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(255, 255, 255, 1);
  width: 30%;
  margin-bottom: 1rem;
  padding: 3px;
  border-radius: 10px;
}

.arrow-left, .arrow-right {
  font-size: 1.6rem;
  color: rgba(130, 130, 151, 1);
  cursor: not-allowed; 
  justify-content: space-between;
  margin: 0.5rem 10px;
}

.arrow-left:hover, .arrow-right:hover {
  color: rgba(130, 130, 151, 1);
  cursor: pointer;
}

/* Lisa Tietoa Section */
.lisa-tietoa {
  margin: 0 0;
}

.lisa-tietoa h1 {
  margin-bottom: 0.5rem;
  font-size: 2.5rem;
}

.lisa-tietoa h1::after {
  width: 57.3%;
  left: 21%;
}

.info-card-container {
  display: block;
}

.info-card {
  height: 28.5rem;
  margin: 3rem auto;
  width: 80%;
}
}

@media (min-width: 768px) and (max-width: 992px) {
.etusivu {
  padding-top: 7rem;
  height: 70rem;
}

.firstpage {
  display: block;
  margin-left: 50px;
  margin-right: 0px;
}

.medical-image {
  margin-top: 0rem;
  max-width: 550px;
}

.video-container iframe{
  left: 8.6rem;
  bottom: 14rem;
  width: 265px;
  height: 165px;
}

.text-container-etusivu {
  display: block;
}

.text-container-etusivu h1 {
  font-size: 2.8rem;
  width: 80%;
}

.kurssit-button {
  width: 8rem;
  max-width: 8rem;
}

/* Palvelumme Section */
.palvelumme {
  margin: 0px 0px;
}

.palvelumme h1::after {
  width: 56%;
  left: 22%;
}

.card-container {
  display: block;
}

.server-card {
  margin: 0 auto;
  width: 80%;
  margin-top: 30px;
}

/* Aikajana Section */
.aikajana-container {
  margin: 50px auto;
  width: 95%;
}

.aikajana h1 {
  font-size: 2.8rem;
  margin-bottom: 1rem;
}

.aikajana h1::after {
  width: 56%; 
  left: 22%;
}

.aikajana p {
  font-size: 17px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.timeline-item {
  margin: 10px auto;
  margin-top: 2rem;
  width: 100%;
}

.year {
  margin: 0 auto;
}

.timeline-frame {
  width: 100%;
  margin: 0 auto 4rem;
}

/* Lisa Tietoa Section */
.lisa-tietoa {
  margin: 0 0;
}

.lisa-tietoa h1 {
  margin-bottom: 0.5rem;
}

.lisa-tietoa h1::after {
  width: 57.3%;
  left: 21%;
}

.info-card-container {
  display: block;
}

.info-card {
  height: 28.5rem;
  margin: 3rem auto;
  width: 80%;
}

.card-links2 {
  margin-top: 2rem;
}
}

@media (min-width: 993px) and (max-width: 1200px) {
  .etusivu {
    height: 43rem;
  }
  
  .firstpage {
    margin-left: 60px;
    margin-right: 50px;
  }
  
  .medical-image {
    max-width: 500px;
  }
  
  .video-container iframe{
    left: 7.96rem;
    bottom: 12.7rem;
    width: 238px;
    height: 150px;
  }
  
  .text-container-etusivu h1 {
    font-size: 2.6rem;
    width: 100%;
  }
  
  .frame-container p {
    font-size: 0.84rem;
  }
  
  /* Palvelumme Section */
  .palvelumme {
    margin: 40px auto;
    width: 90%;
  }
  
  .palvelumme h1 {
    font-size: 2.6rem;
  }
  
  .card-container {
    display: block;
  }
  
  .server-card {
    margin: 30px auto;
    width: 85%;
  }

  /* Aikajana Section */
  .aikajana-small {
    display: none;
  }
  
  .aikajana-container {
    margin: 50px auto;
  }
  
  .aikajana h1 {
    font-size: 2.6rem;
  }
  
  .aikajana h1::after {
    width: 54%; 
    left: 23%;
  }
  
  .timeline-item {
    margin: 2rem 0 10px;
    width: 19%;
  }
  
  .year {
    margin: 0 auto;
    width: 100%;
  }
  
  .timeline-frame {
    width: 95%;
  }

  /* Lisa Tietoa Section */
  .lisa-tietoa {
    text-align: center;
    margin: 50px auto;
  }
  
  .lisa-tietoa h1 {
    font-size: 2.6rem;
  }
  
  .info-card-container {
    display: block;
  }

  .info-card {
    height: auto;
    margin: 3rem auto;
    width: 80%;
    max-width: 43rem;
  }

  .card-links1 , .card-links3{
    margin-bottom: 2rem;
  }
  
  .card-links2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

@media (min-width: 1201px) and (max-width: 1410px) {
  .lisa-tietoa {
    margin: 0 0;
  }
  
  .lisa-tietoa h1 {
    margin-bottom: 0.5rem;
  }
  
  .lisa-tietoa h1::after {
    width: 57.3%;
    left: 21%;
  }
  
  .info-card-container {
    display: block;
  }
  
  .info-card {
    height: auto;
    margin: 3rem auto;
    width: 80%;
    max-width: 43rem;
  }

  .card-links1 , .card-links3{
    margin-bottom: 2rem;
  }
  
  .card-links2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
