.screen {
  max-width: 1439px;
  margin: 0 auto;
}

.lääketiede {
  background-color: #ECF9FF;
  padding-top: 9rem;
  height: 43rem;
}

.lääketiede-main {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  max-width: 1439px;
  width: 100%;
  margin: 0 auto;
}

.lääketiede-main h1 {
  font-size: 2.8rem;
  color: rgba(6, 6, 64, 1);
  margin-bottom: 1.9rem;
}

.lääketiede-main h1::after {
  content: "";
  display: block;
  border-bottom: 5px solid white;
  width: 86%;
  position: absolute;
  left: 7%;
  border-radius: 15px; 
}

.lääketiede-main p {
  font-size: 17px;
  margin-left: 5rem;
  margin-right: 5rem;
  color: rgba(130, 130, 151, 1);
}

.lääketiede-main h1,
.lääketiede-main p {
    opacity: 5;
}

.lääketiede-pic-frame {
  margin-top: 2rem;
  margin-left: 6rem;
  margin-right: 6rem;
  border-radius: 15px;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}

.lääketiede-pic-frame::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ECF9FF; 
  opacity: 0.9; 
  border-radius: 15px;
}

.myRomania {
  background-color: #ECF9FF;
  height: 55rem;
}

.myRomania h1 {
    text-align: center;
    margin: 0;
    padding-top: 6.5rem;
    font-size: 2.3rem;
    color: rgba(6, 6, 64, 1);
}

.myRomania h1::after {
    content: "";
    display: block;
    border-bottom: 5px solid #fff;
    width: 60%;
    position: relative;
    left: 20%;
    border-radius: 15px;
}

.lääketiede-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.lääketiede-text1 {
    width: 80%;
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    padding: 20px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: -2px 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.text1-content {
    width: 60%;
    margin-top: 0;
    color: rgba(6, 6, 64, 1);
}

.text1-content p {
  margin-bottom: 0;
  color: rgba(130, 130, 151, 1);
  margin-left: 1.5rem;
} 

.text2-content {
    width: 40%;
    padding: 20px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: -4px 4px 4px 4px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
}

.text2-content h2 {
    margin-top: 1rem;
    color: rgba(6, 6, 64, 1);
}

.text2-content p {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    font-size: 17.5px;
    color: rgba(130, 130, 151, 1);
}

.text2-content button {
    background-color: rgba(6, 6, 64, 1);
    box-shadow: -2px 2px 2px 2px rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 7rem;
    max-width: 7rem;
    display: block;
    margin: 0 auto;
}

.text2-content button:hover {
  background-color: rgba(6, 6, 64, 0.8);
}

.overlay-lääkeromania {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1300;
}

.form-container-lääkeromania {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 80%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  z-index: 1350;
  border-radius: 15px;
}

.x-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 35px;
  cursor: pointer;
}

.mylääkeromania {
  margin-top: 3rem;
  padding: 1rem;
}

.lääkeromania-wrapper h2 {
  text-align: center;
  font-size: 24px;
  margin: 10px 0;
  color: rgba(6, 6, 64, 1);
}

.mylääkeromania p {
  margin-top: 1rem;
  font-size: 16px;
  color: #555;
  width: 100%;
}

.lääkeromania-content {
  margin-top: 2rem;
}

@media screen and (max-width: 600px) {
  .lääketiede {
    padding-top: 7rem;
    height: 30rem;
    width: auto;
  }
  
  .lääketiede-main h1 {
    font-size: 1.5rem;
    margin-bottom: 1.3rem;
  }
  
  .lääketiede-main h1::after {
    width: 86%;
    position: relative;
    left: 7%;
  }
  
  .lääketiede-main p {
    font-size: 14px;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  
  .lääketiede-pic-frame {
    margin: 2rem auto;
    width: 90%;
    height: 100%;
  }

  .myRomania {
    background-color: #ECF9FF;
    height: 85rem;
  }

  .myRomania h1 {
    margin-bottom: 1rem;
    font-size: 1.7rem;
    padding-top: 5rem;
  }

  .myRomania h1::after {
    width: 71.5%;
    left: 14%;
  }

  .lääketiede-text-container {
    display: block;
    margin: 0 0;
  }

  .lääketiede-text1 {
    width: 90%;
    display: block;
    margin: 0 auto;
    padding: 15px;
    box-shadow: -1px 1px 1px 1px rgba(0, 0, 0, 0.1);
  }

  .text1-content {
    width: 100%;
    font-size: 0.9rem;
  } 

  .text1-content h2{
    width: 100%;
    font-size: 1.3rem;
  }

  .text1-content p {
    font-size: 15px;
  } 

  .text2-content {
    width: 100%;
    padding: 15px;
    margin-top: 2rem;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.2);
    display: block;
  }

  .text2-content h2 {
    margin-top: 0.5rem;
    font-size: 1.2rem;
  }

  .text2-content p {
    font-size: 15px;
  }

  .form-container-lääkeromania {
    padding: 10px;
    overflow: scroll;
  }

  .x-button {
    font-size: 30px;
  }

  .mylääkeromania {
    margin-top: 0rem;
  }

  .lääkeromania-wrapper h2 {
    font-size: 22px;
  }

  .mylääkeromania p {
    font-size: 15px;
  }
}

@media (min-width: 601px) and (max-width: 768px) {
  .lääketiede {
    padding-top: 7rem;
    height: 30rem;
    width: auto;
  }
  
  .lääketiede-main h1 {
    font-size: 1.5rem;
    margin-bottom: 1.3rem;
  }
  
  .lääketiede-main h1::after {
    width: 86%;
    position: relative;
    left: 7%;
  }
  
  .lääketiede-main p {
    font-size: 14px;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  
  .lääketiede-pic-frame {
    margin: 2rem auto;
    width: 90%;
    height: 100%;
  }

  .myRomania {
    background-color: #ECF9FF;
    height: 64rem;
  }

  .myRomania h1 {
    font-size: 1.7rem;
    padding-top: 5rem;
  }

  .myRomania h1::after {
    width: 71.5%;
    left: 14%;
  }

  .lääketiede-text-container {
    display: block;
    margin: 0px 0px;
  }

  .lääketiede-text1 {
    width: 90%;
    display: block;
    margin: 20px auto;
    padding: 15px;
    box-shadow: -1px 1px 1px 1px rgba(0, 0, 0, 0.1);
  }

  .text1-content {
    width: 100%;
    font-size: 0.9rem;
  } 

  .text1-content h2{
    width: 100%;
    font-size: 1.3rem;
  }

  .text1-content p {
    font-size: 15px;
  } 

  .text2-content {
    width: 100%;
    padding: 15px;
    margin-top: 2rem;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.2);
    display: block;
  }

  .text2-content h2 {
    margin-top: 0.5rem;
    font-size: 1.2rem;
  }

  .text2-content p {
    font-size: 15px;
  }

  .form-container-lääkeromania {
    padding: 10px;
    overflow: scroll;
  }

  .x-button {
    font-size: 30px;
  }

  .mylääkeromania {
    margin-top: 0rem;
  }

  .lääkeromania-wrapper h2 {
    font-size: 22px;
  }

  .mylääkeromania p {
    font-size: 15px;
  }
}

@media (min-width: 769px) and (max-width: 1144px) {
  .lääketiede {
    padding-top: 7rem;
    height: 30rem;
    width: auto;
  }
  
  .lääketiede-main h1 {
    font-size: 1.5rem;
    margin-bottom: 1.3rem;
  }
  
  .lääketiede-main h1::after {
    width: 86%;
    position: relative;
    left: 7%;
  }
  
  .lääketiede-main p {
    font-size: 14px;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  
  .lääketiede-pic-frame {
    margin: 2rem auto;
    width: 90%;
    height: 100%;
  }

  .myRomania {
    background-color: #ECF9FF;
    height: 59rem;
  }

  .myRomania h1 {
    font-size: 1.7rem;
    padding-top: 5rem;
  }

  .myRomania h1::after {
    width: 71.5%;
    left: 14%;
  }

  .lääketiede-text-container {
    display: block;
    margin: 0px 0px;
  }

  .lääketiede-text1 {
    width: 90%;
    display: block;
    margin: 20px auto;
    padding: 15px;
    box-shadow: -1px 1px 1px 1px rgba(0, 0, 0, 0.1);
  }

  .text1-content {
    width: 100%;
    font-size: 0.9rem;
  } 

  .text1-content h2{
    width: 100%;
    font-size: 1.3rem;
  }

  .text1-content p {
    font-size: 15px;
  } 

  .text2-content {
    width: 100%;
    padding: 15px;
    margin-top: 2rem;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.2);
    display: block;
  }

  .text2-content h2 {
    margin-top: 0.5rem;
    font-size: 1.2rem;
  }

  .text2-content p {
    font-size: 15px;
  }

  .form-container-lääkeromania {
    padding: 10px;
    overflow: scroll;
  }

  .x-button {
    font-size: 30px;
  }

  .mylääkeromania {
    margin-top: 0;
  }

  .lääkeromania-wrapper h2 {
    font-size: 22px;
  }

  .mylääkeromania p {
    font-size: 15px;
  }
}
