/* Ota-yhteyttä section */

  .screen {
    max-width: 1433px;
    margin: 0 auto;
  }

  .contact-container {
    position: relative;
  }

  .company-info {
    display: none;
  }

  .contact-image {
    position: absolute;
    top: 90px;
    right: 60px; 
  }

  .ota-yhteyttä {
    background-color: #ECF9FF;
    padding: 20px 0;
  }

  .ota-yhteyttä h1 {
    color: rgba(6, 6, 64, 1);
    font-size: 2.8rem;
    margin-left: 120px;
    margin-right: 120px;
  }
  
  .inputs {
    display: flex;
    flex-direction: column;
    margin-left: 120px;
    width: 35%;
  }
  
  .inputs input{
    border-radius: 10px;
    width: 100%;
    padding: 10px;
    border: none;
    box-shadow: none;
    margin-bottom: 1rem;
  }

  .inputs textarea {
    height: 6.5rem;
    border-radius: 10px;
    width: 100%;
    padding: 8px;
    border: none;
    box-shadow: none;
  }
  
  .button-lähetä {
    background-color: rgba(6, 6, 64, 1);
    margin-top: 20px;
    color: white;
    padding: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    max-width: 7rem;
    display: block;
    margin-left: 7.5rem;
    margin-bottom: 3rem;
    transition: background-color 0.3s;
  }
  
  .button-lähetä:hover {
    background-color: rgba(6, 6, 64, 0.8);
  }

  .message-sent {
    color: green;
    font-weight: bold;
  }
  
  .red-border {
    border: 2px solid red;
  }
  
@media screen and (max-width: 600px) {
/* Ota-yhteyttä section */
.contact-image {
  display: none;
}

.ota-yhteyttä {
  background-color: #ECF9FF;
  padding: 17px 0;
}

.ota-yhteyttä h1 {
  font-size: 1.9rem;
  margin-left: 2rem;
  margin-bottom: 2rem;
}

.inputs {
  margin-left: 2rem;
  margin-right: 0px;
  width: 75%;
}

.button-lähetä {
  margin-left: 2rem;
}
}

@media (min-width: 601px) and (max-width: 768px) {
  /* Ota-yhteyttä section */
.contact-image {
  display: none;
}

.ota-yhteyttä h1 {
  font-size: 2.2rem;
  margin-left: 4rem;
  margin-bottom: 2rem;
}

.inputs {
  margin-left: 4rem;
  margin-right: 0;
  width: 30rem;
  max-width: 38rem;
}

.button-lähetä {
  margin-left: 4rem;
}
}

@media (min-width: 768px) and (max-width: 992px) {
/* Ota-yhteyttä section */
.contact-image {
  display: none;
}

.ota-yhteyttä h1 {
  font-size: 2.2rem;
  margin-left: 6.5rem;
  margin-bottom: 2rem;
}

.inputs {
  margin-left: 6.5rem;
  margin-right: 0px;
  width: 33rem;
  max-width: 40rem;
}

.button-lähetä {
  margin-left: 6.5rem;
}
}

@media (min-width: 993px) and (max-width: 1200px) {
/* Ota-yhteyttä section */
.contact-image {
  display: none;
}

.ota-yhteyttä h1 {
  margin-left: 6.5rem;
  margin-bottom: 2rem;
}

.inputs {
  margin-left: 6.5rem;
  margin-right: 0px;
  width: 33rem;
  max-width: 40rem;
}

.button-lähetä {
  margin-left: 6.5rem;
}
}

