
  .screen {
    max-width: 1433px;
    margin: 0 auto;
  }

  .romania-pic-frame iframe {
    width: 100%;
    height: 21rem;
  }
  
  .romania {
    background-color: #ECF9FF;
    padding-top: 6rem;
    height: 57em;
    max-width: 100%;
  }

  .romania-main {
    text-align: center;
    margin: 60px 120px;
  }

  .romania-main h1{
    font-size: 2.8rem;
    color: rgba(6, 6, 64, 1);
    margin-bottom: 1.9rem;
  }

  .romania-main h1::after {
    content: "";
    display: block;
    border-bottom: 5px solid white;
    width: 50%;
    position: absolute;
    left: 25%;
    border-radius: 15px; 
  }

  .romania-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; 
  }

  .romania-card {
    width: 90%;
    height: 37rem;
    margin-top: 5px;
    border-radius: 15px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    text-align: left;
    overflow: hidden;
  }

  .romania-card-pic {
    width: 100%;
    height: 20rem;
    border-radius: 15px 15px 0 0;
    mask-image: linear-gradient(to bottom, rgba(236, 249, 255, 1), transparent 20rem);
  }

  .outside-romania {
    display: flex;
    background-color: #fff;
    margin-top: 0.8rem;
  }
  
  .lääketiede-romania-text {
    flex: 1;
    padding: 10px;
    text-align: left;
  }

  .tentti-romania-text {
    flex: 1;
    padding: 10px;
    border-left: 1px solid #ddd;
    text-align: left;
  }
  
  .lääketiede-romania-text h2 {
    color: rgba(6, 6, 64, 1);
    font-size: 24px;
    margin-bottom: 10px;
    margin-left: 1rem;
  }

  .tentti-romania-text h2 {
    color: rgba(6, 6, 64, 1);
    font-size: 24px;
    margin-bottom: 10px;
    margin-left: 1rem;
  }
  
  .lääketiede-romania-text p {
    color: #555;
    margin-bottom: 20px;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .tentti-romania-text p {
    color: #555;
    margin-bottom: 20px;
    margin-left: 1rem;
  }
  
  .lääketiede-romania-text a {
    color: rgb(124, 216, 255);
    border-bottom: 2px solid transparent;
    transition: border-color 0.3s;
    text-decoration: none;
    margin-left: 1rem;
  }
  
  .lääketiede-romania-text a:hover {
    border-color: rgb(124, 216, 255);
  }
  
  .tentti-romania-text a {
    margin-left: 1rem;
    color: rgb(124, 216, 255);
    border-bottom: 2px solid transparent;
    transition: border-color 0.3s;
    text-decoration: none;
  }
  
  .tentti-romania-text a:hover {
    border-color: rgb(124, 216, 255); 
  }

/* Vapaa aika Section */
  .vapaa-aika {
    background-color: #ECF9FF;
  }

  .centered-header {
    text-align: center;
  }

  .centered-header h1 {
    font-size: 2.8rem;
    margin-top: 0;
    padding-top: 1rem;
    color: rgba(6, 6, 64, 1);
    margin-bottom: 20px; 
  }

  .centered-header h1::after {
    content: "";
    display: block;
    border-bottom: 5px solid #fff;
    width: 56.5%;
    position: absolute;
    left: 22%;
    border-radius: 15px; 
  }

  .vapaa-aika-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 7rem;
    margin-right: 7rem;
    margin-top: 3rem;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }

  .vapaa-aika-text {
    margin-left: 2rem;
  }

  .vapaa-aika-text h2 {
    font-size: 28px;
    margin-bottom: 15px; 
    color: rgba(6, 6, 64, 1);
  }

  .vapaa-aika-text p{
    width: 90%;
    color: #555;
    font-size: 17px; 
  }

  .vapaa-aika-image {
    width: 450px;
    height: 300px;
}

.loma-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 7rem;
  margin-right: 7rem;
  margin-top: 2rem;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 20rem;
}

.loma-text {
  margin-left: 3rem;
  width: 65%;
}

.loma-text h2 {
  font-size: 28px;
  margin-bottom: 15px; 
  color: rgba(6, 6, 64, 1);
}


.loma-text p{
  width: 90%;
  color: #555;
  font-size: 22px; 
}

.loma-image {
  width: 480px;
  height: 400px;
}

.harrastus-content {
  display: block;
  margin-left: 7rem;
  margin-right: 7rem;
  margin-top: 2rem;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.harrastus-text {
  text-align: center;
}

.harrastus-text h2 {
  font-size: 24px;
  color: rgba(6, 6, 64, 1);
  margin-bottom: 15px; 
}

.harrastus-text p{
  color: #555;
  font-size: 16px; 
}

.show-form-button {
  background-color: rgba(6, 6, 64, 1);
  margin-top: 8px;
  margin-bottom: 15px;
  color: white;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 10rem;
  max-width: 10rem;
  transition: background-color 0.3s;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1300;
}

.form-container {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 80%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  z-index: 1350;
  border-radius: 15px;
}

.x-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 35px;
  cursor: pointer;
}

.myForm .content-wrapper {
  display: flex;
  justify-content: space-between;
}

.myForm h1 {
  text-align: center;
  font-size: 24px;
  margin: 10px 0;
  color: rgba(6, 6, 64, 1);
}

.myForm p {
  margin-top: 2rem;
  font-size: 16px;
  color: #555;
  width: 50%;
}

.myForm .additional-content {
  width: 45%;
}

.additional-content p {
  width: 100%;
}

@media screen and (max-width: 600px) {
  .romania-pic-frame iframe {
    width: 100%;
    height: 11.95rem;
    margin: 0 auto;
  }
  
  .romania {
    padding-top: 7rem;
    height: auto;
  }

  .romania-main {
    margin: 0 0;
  }

  .romania-main h1{
    font-size: 1.9rem;
    margin-bottom: 1.9rem;
  }

  .romania-card-container {
    display: flex;
  }

  .romania-card {
    margin: 0 auto;
    width: 90%;
    height: auto;
  }

  .romania-card-pic {
    height: 100%;
    width: 100%;
    mask-image: linear-gradient(to bottom, rgba(236, 249, 255, 1), transparent 100%);
  }

  .outside-romania {
    display: block;
    margin-top: 0rem;
  }
  
  .lääketiede-romania-text {
    padding: 7px;
    text-align: left;
    margin-bottom: 1rem;
  }

  .tentti-romania-text {
    margin-bottom: 1rem;
    padding: 7px;
    border-left: 0 solid #ddd;
    border-top: 1px solid #ddd;
  }
  
  .lääketiede-romania-text h2 {
    font-size: 22px;
  }

  .tentti-romania-text h2 {
    font-size: 22px;
    margin-top: 1rem;
  }
  
  .lääketiede-romania-text p {
    color: #555;
    margin-bottom: 16px;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .tentti-romania-text p {
    color: #555;
    margin-bottom: 0;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .vapaa-aika {
    padding-top: 2rem;
    overflow: hidden;
  }

  .centered-header h1 {
    font-size: 1.9rem;
  }

  .centered-header p {
    font-size: 16px;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .vapaa-aika-content {
    display: block;
    margin: 2rem auto;
    width: 90%;
  }

  .vapaa-aika-text {
    margin-left: 1.5rem;
  }

  .vapaa-aika-text h2 {
    font-size: 20px;
  }

  .vapaa-aika-text p{
    width: 95%;
    font-size: 15px; 
    margin-bottom: 1.5rem;
  }

  .vapaa-aika-image {
    width: 100%;
    height: auto;
}

.loma-content {
  display: block;
  margin: 1.5rem auto;
  width: 90%;
  height: 15rem;
}

.loma-text {
  margin-left: 1.5rem;
  width: 95%;
}

.loma-text h2 {
  font-size: 20px;
}

.loma-text p{
  font-size: 16px; 
}

.loma-image {
  display: none;
}

.harrastus-content {
  display: block;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1.5rem;
}

.harrastus-text {
  margin-left: 1rem;
  margin-right: 1rem;
}

.harrastus-text h2 {
  font-size: 18px;
}


.harrastus-text p{
  color: #555;
  font-size: 16px; 
}

.show-form-button {
  margin-top: 3px;
  padding: 0.6rem;
  width: 7rem;
  max-width: 7rem;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1300;
}

.form-container {
  width: 90%;
  height: 80%;
  overflow: scroll;
}

.x-button {
  font-size: 30px;
}

.myForm .content-wrapper {
  display: block;
}

.myForm h1 {
  font-size: 20px;
}

.myForm p {
  margin-top: 2rem;
  font-size: 15px;
  width: 100%;
}

.myForm .additional-content {
  width: 100%;
}

.additional-content p {
  width: 100%;
}
}

@media (min-width: 601px) and (max-width: 768px) {
  .romania {
    padding-top: 7rem;
    height: auto;
  }

  .romania-main {
    margin: 0 0;
  }

  .romania-main h1{
    font-size: 2.1rem;
    margin-bottom: 1.2rem;
  }

  .romania-card-container {
    display: flex;
  }

  .romania-card {
    margin: 0 auto;
    width: 90%;
    height: auto;
  }

  .romania-card-pic {
    height: 100%;
    width: 100%;
    mask-image: linear-gradient(to bottom, rgba(236, 249, 255, 1), transparent 100%);
  }

  .outside-romania {
    display: block;
    margin-top: 0;
  }
  
  .lääketiede-romania-text {
    padding: 7px;
    text-align: left;
    margin-bottom: 1rem;
  }

  .tentti-romania-text {
    margin-bottom: 1rem;
    padding: 7px;
    border-left: 0 solid #ddd;
    border-top: 1px solid #ddd;
  }
  
  .lääketiede-romania-text h2 {
    font-size: 22px;
  }

  .tentti-romania-text h2 {
    font-size: 22px;
    margin-top: 1rem;
  }
  
  .lääketiede-romania-text p {
    color: #555;
    margin-bottom: 16px;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .tentti-romania-text p {
    color: #555;
    margin-bottom: 16px;
    margin-left: 1rem;
  }

  .vapaa-aika {
    padding-top: 2rem;
    overflow: hidden;
  }

  .centered-header h1 {
    font-size: 1.9rem;
  }

  .centered-header p {
    font-size: 16px;
    width: 85%;
    margin: 0 auto;
  }

  .vapaa-aika-content {
    display: block;
    margin: 2rem auto;
    width: 90%;
  }

  .vapaa-aika-text {
    margin-left: 1.5rem;
  }

  .vapaa-aika-text h2 {
    font-size: 20px;
  }

  .vapaa-aika-text p{
    width: 95%;
    font-size: 15px; 
    margin-bottom: 1.5rem;
  }

  .vapaa-aika-image {
    width: 100%;
    height: auto;
}

.loma-content {
  display: flex;
  margin: 0 auto;
  width: 90%;
  height: 20rem;
}

.loma-text {
  margin-left: 1rem;
  width: 100%;
}

.loma-text h2 {
  font-size: 24px;
}

.loma-text p{
  font-size: 18px; 
}

.loma-image {
  width: 320px;
  height: 100%;
}

.harrastus-content {
  display: block;
  margin: 1.5rem auto;
  width: 90%;
}

.harrastus-text {
  margin-left: 1rem;
  margin-right: 1rem;
}

.harrastus-text h2 {
  font-size: 18px;
}

.harrastus-text p{
  color: #555;
  font-size: 16px; 
}

.show-form-button {
  margin-top: 3px;
  padding: 0.6rem;
  width: 7rem;
  max-width: 7rem;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1300;
}

.form-container {
  width: 90%;
  height: 80%;
  overflow: scroll;
}

.x-button {
  font-size: 30px;
}

.myForm .content-wrapper {
  display: block;
}

.myForm h1 {
  font-size: 20px;
}

.myForm p {
  margin-top: 2rem;
  font-size: 15px;
  width: 100%;
}

.myForm .additional-content {
  width: 100%;
}

.additional-content p {
  width: 100%;
}
}

@media (min-width: 769px) and (max-width: 1235px) {
  .romania {
    padding-top: 7rem;
    height: auto;
  }

  .romania-main {
    margin: 0px 0px;
  }

  .romania-main h1{
    font-size: 2.6rem;
    margin-bottom: 1.2rem;
  }

  .romania-card-container {
    display: flex;
  }

  .romania-card {
    margin: 0 auto;
    width: 90%;
    max-width: 40rem;
    height: auto;
  }

  .romania-card-pic {
    height: 100%;
    width: 100%;
    mask-image: linear-gradient(to bottom, rgba(236, 249, 255, 1), transparent 100%);
  }

  .outside-romania {
    display: block;
    margin-top: 0rem;
  }
  
  .lääketiede-romania-text {
    padding: 7px;
    text-align: left;
    margin-bottom: 1rem;
  }

  .tentti-romania-text {
    padding: 7px;
    margin-bottom: 1rem;
    border-left: 0 solid #ddd;
    border-top: 1px solid #ddd;
  }
  
  .lääketiede-romania-text h2 {
    font-size: 22px;
  }

  .tentti-romania-text h2 {
    font-size: 22px;
    margin-top: 1rem;
  }
  
  .lääketiede-romania-text p {
    color: #555;
    margin-bottom: 16px;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .tentti-romania-text p {
    color: #555;
    margin-bottom: 16px;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .vapaa-aika {
    padding-top: 2rem;
    overflow: hidden;
  }

  .centered-header h1 {
    font-size: 2.6rem;
  }

  .centered-header p {
    font-size: 16px;
    width: 80%;
    margin: 0 auto;
    max-width: 30rem;
  }

  .vapaa-aika-content {
    display: block;
    margin: 2rem auto;
    width: 90%;
    max-width: 40rem;
  }

  .vapaa-aika-text {
    margin-left: 1.5rem;
  }

  .vapaa-aika-text h2 {
    font-size: 20px;
  }

  .vapaa-aika-text p{
    width: 95%;
    font-size: 15px; 
    margin-bottom: 1.5rem;
  }

  .vapaa-aika-image {
    width: 100%;
    height: auto;
}

.loma-content {
  display: flex;
  margin: 0 auto;
  width: 90%;
  max-width: 40rem;
  height: 20rem;
}

.loma-text {
  margin-left: 3rem;
  width: 100%;
}

.loma-text h2 {
  font-size: 24px;
}

.loma-text p{
  font-size: 18px; 
}

.loma-image {
  width: 320px;
  height: 100%;
}

.harrastus-content {
  display: block;
  margin: 1.5rem auto;
  width: 90%;
  max-width: 40rem;
}

.harrastus-text {
  margin-left: 1rem;
  margin-right: 1rem;
}

.harrastus-text h2 {
  font-size: 18px;
}


.harrastus-text p{
  color: #555;
  font-size: 16px; 
}

.show-form-button {
  margin-top: 3px;
  padding: 0.6rem;
  width: 7rem;
  max-width: 7rem;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1300;
}

.form-container {
  width: 90%;
  height: 80%;
  overflow: scroll;
}

.x-button {
  font-size: 30px;
}

.myForm .content-wrapper {
  display: block;
}

.myForm h1 {
  font-size: 20px;
}

/* Style for the text inside the form */
.myForm p {
  margin-top: 2rem;
  font-size: 15px;
  width: 100%;
}

.myForm .additional-content {
  width: 100%;
}

.additional-content p {
  width: 100%;
}
}