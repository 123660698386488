.carousel-container {
  display: flex;
  justify-content: space-between;
  margin: 3rem 7rem;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.video-container-small {
  margin-top: 3rem;
  margin-bottom: 2rem;
  margin-right: 4rem;
  position: relative;
  text-align: center;
}

.video-container-small iframe {
  width: 350px;
  height: 180px;
  border-radius: 15px;
}

.arrow-container {
  margin-top: 15px; 
}

.arrow-icon {
  font-size: 24px;
  cursor: pointer;
  margin: 0 25px;
}

.text-container {
  padding: 30px;
}

.text-container h2 {
  font-size: 28px;
  margin-bottom: 15px; 
  color: rgba(6, 6, 64, 1);
}

.text-container p {
  width: 90%;
  color: #555;
  font-size: 17px; 
}

@media screen and (max-width: 600px) {
.carousel-container {
  display: block;
  margin: 2rem auto;
  width: 90%;
}

.video-container-small {
  margin-top: 0rem;
  margin-bottom: 2rem;
  margin-right: 0rem;
  text-align: center;
}

.video-container-small iframe {
  width: 90%;
  height: 180px;
  margin: 0 auto;
}

.arrow-container {
  margin-top: 15px; 
}

.arrow-icon {
  font-size: 22px;
  cursor: pointer;
  margin: 0 25px;
}

.text-container {
  padding: 25px;
}

.text-container h2 {
  font-size: 22px;
  margin-top: 0;
}

.text-container p {
  width: 100%;
  color: #555;
  font-size: 15px; 
}
}

@media (min-width: 601px) and (max-width: 768px) {
.carousel-container {
  display: block;
  margin: 2rem auto;
  width: 90%;
}

.video-container-small {
  margin-top: 0rem;
  margin-bottom: 2rem;
  margin-right: 0rem;
  text-align: center;
}

.video-container-small iframe {
  width: 90%;
  height: 180px;
  margin: 0 auto;
}

.arrow-container {
  margin-top: 15px; 
}

.arrow-icon {
  font-size: 22px;
  cursor: pointer;
  margin: 0 25px;
}

.text-container {
  padding: 25px;
}

.text-container h2 {
  font-size: 22px;
  margin-top: 0;
}

.text-container p {
  width: 100%;
  color: #555;
  font-size: 15px; 
}  
}

@media (min-width: 769px) and (max-width: 1235px) {
  .carousel-container {
    display: block;
    margin: 2rem auto;
    width: 90%;
    max-width: 40rem;
  }
  
  .video-container-small {
    margin-top: 0rem;
    margin-bottom: 2rem;
    margin-right: 0rem;
    text-align: center;
  }
  
  .video-container-small iframe {
    width: 90%;
    height: 180px;
    margin: 0 auto;
  }
  
  .arrow-container {
    margin-top: 15px; 
  }
  
  .arrow-icon {
    font-size: 22px;
    cursor: pointer;
    margin: 0 25px;
  }
  
  .text-container {
    padding: 25px;
  }
  
  .text-container h2 {
    font-size: 22px;
    margin-top: 0;
  }
  
  .text-container p {
    width: 100%;
    color: #555;
    font-size: 15px; 
  }
}
