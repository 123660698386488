  .screen {
    max-width: 1433px;
    margin: 0 auto;
  }

  .yhteistiedot {
    background-color: #ECF9FF;
    padding-top: 2rem;
    height: 55rem;
  }

  .yhteistiedot-main {
    text-align: center;
    margin: 120px 120px;
  }

  .email-form-container .ota-yhteyttä h1::after {
    content: "";
    display: block;
    border-bottom: 5px solid white;
    width: 80.5%;
    position: relative;
    left: 10%;
    border-radius: 15px; 
  }

  .yhteistiedot-main .contact-image {
    display: none;
  }

  .yhteistiedot-main .ota-yhteyttä form {
    margin-top: 4rem;
  }

  .yhteistiedot-main .company-info {
    display: block;
    text-align: center;
    position: absolute;
    bottom: 140px;
    right: 140px;
    padding: 20px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0px 2px 1px 1px rgba(0, 0, 0, 0.2);
    height: 19.3rem;
    width: 28rem;
    overflow: hidden;
  }

  .yhteistiedot-main .ota-yhteyttä .company-info h2 {
    color: rgba(6, 6, 64, 1);
    font-size: 2rem;
    margin: 0;
  }

  .yhteistiedot-main .ota-yhteyttä .company-info p {
    color: rgba(6, 6, 64, 1);
    margin: 0;
  }

  .yhteistiedot-main .social-media {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  
  .yhteistiedot-main .social-media a {
    margin-top: 1rem;
    font-size: 22px;
    transition: color 0.3s ease;
  }

  @media screen and (max-width: 1351px) {
    .yhteistiedot {
      padding-top: 6rem;
      
    }
  
    .yhteistiedot-main {
      text-align: center;
      margin: 0 0;
      font-family: 'Zen Antique Soft', sans-serif;
    }

    .yhteistiedot-main .ota-yhteyttä form {
      margin-top: 2rem;
      margin-left: auto;
      margin-right: auto;
    }
    .yhteistiedot-main .ota-yhteyttä {
      height: 55rem;
    }

    .email-form-container .ota-yhteyttä h1 {
      font-size: 1.9rem;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0;
    }

    .email-form-container .ota-yhteyttä .inputs {
      margin-left: auto;
      margin-right: auto;
    }

    .email-form-container .ota-yhteyttä .button-lähetä {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2.3rem;
    }

    .yhteistiedot-main .ota-yhteyttä .company-info h2 {
      font-size: 1.7rem;
    }

    .yhteistiedot-main .company-info {
      margin-left: auto;
      margin-right: auto;
      position: relative;
      bottom: auto;
      right: auto;
      width: 85%;
      max-width: 36rem;
      height: auto;
    }
}

