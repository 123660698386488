.screen {
  max-width: 1439px;
  margin: 0 auto;
}

.kurssitsivu {
  background-color: #ECF9FF;
  padding-top: 6rem;
}

.kurssit {
  text-align: center;
  margin: 60px 120px;
}

.kurssit h1 {
  font-size: 2.8rem;
  color: rgba(6, 6, 64, 1);
  margin-bottom: 1.9rem;
}

.kurssit h1::after {
  content: "";
  display: block;
  border-bottom: 5px solid white;
  width: 50%;
  position: absolute;
  left: 25%;
  border-radius: 15px; 
}

.kurssi-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.kurssi-card-pic {
  width: 100%;
  height: 13.5rem;
  border-radius: 15px 15px 0 0;
  mask-image: linear-gradient(to bottom, rgba(236, 249, 255, 0.9), transparent 220px);
  object-fit: cover;
}

.kurssi-card {
  height: 33rem;
  width: calc(33.33% - 20px);
  margin: 5px 10px 40px;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  text-align: left;
  overflow: hidden; 
}

.kurssi-card-small {
  height: 27rem;
  width: calc(33.33% - 20px);
  margin: 40px 10px;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  text-align: left;
  overflow: hidden; 
}

.kurssi-header {
  display: flex; 
  align-items: center;
}

.kurssi-header h2 {
  font-size: 1.5rem;
  margin-left: 1rem;
  color: rgba(6, 6, 64, 1);
}

.kurssi-header p {
  margin-top: 1.37rem;
  font-size: 16px;
  color: rgba(6, 6, 64, 1);
  margin-left: 1.6rem;
}

.kurssi-header1 {
  display: flex;
  align-items: center;
}

.kurssi-header1 h2 {
  color: rgba(6, 6, 64, 1);
  font-size: 1.5rem;
  margin-left: 1rem;
}

.kurssi-header1 p {
  margin-top: 1.37rem;
  font-size: 16px;
  color: rgba(6, 6, 64, 1);
  margin-left: 7.8rem;
}

.kurssi-text p {
  margin-top: 0rem;
  font-size: 17px;
  color: rgba(130, 130, 151, 1);
  margin-left: 1rem;
}

.p-with-dots::before {
  content: '\2022'; 
  color: rgba(6, 6, 64, 1);
  font-size: 1rem; 
  margin-right: 0.5rem;
}

.ilmottaudu-button-container {
  display: flex;
  align-items: center; 
  margin-top: 1.5rem;
}

.price-label {
  font-family: 'Zen Antique Soft', sans-serif;
  margin-right: 7rem; 
  margin-left: 3rem;
  font-size: 26px;
  color: rgba(0, 229, 204, 1);
}

.ilmottaudu-button {
  width: 17rem;
  justify-content: flex-end;
  font-family: 'Zen Antique Soft', sans-serif;
  background-color: rgba(6, 6, 64, 1);
  color: white;
  padding: 0.8rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  max-width: 7rem;
  transition: background-color 0.3s; 
}
.ilmottaudu-button:hover {
  background-color: rgba(6, 6, 64, 0.8);
}

/* Small version of cards Section */
.kurssi-card-small {
  height: 27rem;
  font-family: 'Zen Antique Soft', sans-serif;
  width: calc(33.33% - 20px);
  margin: 40px 10px;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  text-align: left;
  overflow: hidden; 
}

.ilmottaudu-button-container-small {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
}

.price-label-small {
  margin-right: 7rem;
  margin-left: 4rem;
  font-size: 26px;
  color: rgba(0, 229, 204, 1);
}

.ilmottaudu-button-small {
  width: 17rem;
  justify-content: flex-end; 
  background-color: rgba(6, 6, 64, 1);
  color: white;
  padding: 0.8rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  max-width: 7rem;
  transition: background-color 0.3s;
}

.ilmottaudu-button-small:hover {
  background-color: rgba(6, 6, 64, 0.8); 
}

/* Enrollment Form Section */
.enrollment-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
}

.enrollment-form {
  background: #fff;
  padding: 20px;
  border-radius: 15px;
  position: relative;
  max-width: 400px;
  width: 100rem;
  text-align: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.enrollment-form h1 {
  color: rgba(6, 6, 64, 1);
}

.enrollment-form h1::after {
  content: "";
  display: block;
  border-bottom: 5px solid #ECF9FF;
  width: 75%;
  position: absolute;
  left: 12.7%;
  border-radius: 15px;
}

.enrollment-form h2 {
  font-size: 24px;
  color: rgba(6, 6, 64, 1);
}

.enrollment-form form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.enrollment-form input {
  margin: 5px 0;
  padding: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 15px;
}

/* Style for the custom dropdown */
.tutkinto-dropdown {
  position: relative;
  width: 100%;
}

.tutkinto-dropdown input {
  margin: 5px 0;
  padding: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 15px;
  cursor: pointer;
  position: relative;
}

.tutkinto-dropdown::after {
  content: "▼";
  position: absolute;
  top: 50%;
  right: 10px;
  color: rgba(6, 6, 64, 1);
  transform: translateY(-50%);
}

.milloin-dropdown {
  position: relative;
  width: 100%;
}

.milloin-dropdown input {
  margin: 5px 0;
  padding: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 15px;
  cursor: pointer;
  position: relative;
}

.milloin-dropdown::after {
  content: "▼";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: rgba(6, 6, 64, 1);
}

.options-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 15px;
  z-index: 1;
}

.options-list div {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.options-list div:hover {
  background-color: #f0f0f0;
  border-radius: 15px;
}

.option {
  cursor: pointer;
}

.submit-button {
  background-color: rgba(6, 6, 64, 1);
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.submit-button:hover {
  background-color: rgba(6, 6, 64, 0.8);
}

.success-message {
  background: #2ecc71;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

.error-message {
  color: #ff0000;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
}


@media screen and (max-width: 600px) {
.kurssit {
  margin: 0 0;
  padding-top: 1rem;
}

.kurssit h1 {
  font-size: 1.9rem;
  margin-bottom: 0;
}

.kurssit h1::after {
  left: 24%;
}

.kurssi-card-container {
  display: block;
}

.kurssi-card {
  width: 90%;
  height: 32rem;
  max-width: 22rem;
  margin: 30px auto;
}

.kurssi-header h2 {
  font-size: 1.3rem;
}

.kurssi-header p {
  font-size: 14px;
  margin-left: 1.5rem;
}

.kurssi-header1 h2 {
  font-size: 1.3rem;
}

.kurssi-header1 p {
  margin-left: 6.83rem;
  font-size: 14px;
}

.kurssi-text p {
  font-size: 16px;
  margin-right: 1rem;
}

.price-label {
  margin-right: 5rem;
  margin-left: 3rem;
  font-size: 25px;
}

/* Small version of cards Section */
.kurssi-card-small {
  width: 90%;
  height: 26rem;
  max-width: 22rem;
  margin: 30px auto;
}

.price-label-small {
  margin-right: 5.5rem;
  margin-left: 3rem;
  font-size: 25px;
}

/* Enrollment Form Section */
.enrollment-form {
  width: 22rem;
  z-index: 1200;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
}

.enrollment-form h1::after {
  content: "";
  display: block;
  border-bottom: 5px solid #ECF9FF;
  width: 80%;
  position: absolute;
  left: 10.3%;
  border-radius: 15px;
}

.enrollment-form h2 {
  font-size: 20px;
}

/* Style for the custom dropdown */
.tutkinto-dropdown {
  position: relative;
  width: 100%;
  font-size: 16px;
}

.options-list div {
  padding: 7px;
  cursor: pointer;
  transition: background-color 0.3s;
}
}

@media (min-width: 601px) and (max-width: 768px) {
  .kurssitsivu {
    background-color: #ECF9FF;
    padding-top: 7rem;
  }
  
  .kurssit {
    margin: 30px auto;
  }
  
  .kurssit h1 {
    font-size: 2.5rem;
    margin-bottom: 1.3rem;
  }
  
  .kurssi-card-container {
    display: block;
  }
  
  .kurssi-card {
    width: 25rem;
    height: 33rem;
    max-width: 24rem;
    margin: 30px auto;
  }

  /* Small version of cards Section */
  .kurssi-card-small {
    width: 25rem;
    height: 27rem;
    max-width: 24rem;
    margin: 30px auto;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .kurssit {
    margin: 30px auto;
  }
  
  .kurssit h1 {
    font-size: 2.5rem;
    margin-bottom: 1.3rem;
  }
  
  .kurssi-card-container {
    display: block;
  }
  
  .kurssi-card {
    width: 25rem;
    height: 33rem;
    max-width: 24rem;
    margin: 30px auto;
  }

  /* Small version of cards Section */
  .kurssi-card-small {
    width: 25rem;
    height: 27rem;
    max-width: 24rem;
    margin: 30px auto;
  }
}

@media (min-width: 992px) and (max-width: 1400px) {
  .kurssit {
    margin: 5rem auto 30px;
  }
  
  .kurssit h1 {
    font-size: 2.5rem;
    margin-bottom: 1.3rem;
  }
  
  .kurssi-card-container {
    display: block;
  }
  
  .kurssi-card {
    width: 25rem;
    height: 33rem;
    max-width: 24rem;
    margin: 30px auto;
  }

  /* Small version of cards Section */
  .kurssi-card-small {
    width: 25rem;
    height: 27rem;
    max-width: 24rem;
    margin: 30px auto;
  }
}
