  .screen {
    max-width: 1433px;
    margin: 0 auto;
  }

  .tentti {
    padding-top: 9rem;
    height: 43rem;
    background-color: #ECF9FF;
  }

  .tentti-main {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    max-width: 1439px;
    width: 100%;
    margin: 0 auto;
  }
  
  .tentti-main h1 {
    font-size: 2.8rem;
    color: rgba(6, 6, 64, 1);
    margin-bottom: 1.9rem;
  }
  
  .tentti-main h1::after {
    content: "";
    display: block;
    border-bottom: 5px solid white;
    width: 86%;
    position: absolute;
    left: 7%;
    border-radius: 15px; 
  }
  
  .tentti-main p {
    font-size: 17px;
    margin-left: 5rem;
    margin-right: 5rem;
    color: rgba(130, 130, 151, 1);
  }
  
  .tentti-main h1,
  .tentti-main p {
      opacity: 5;
  }
  
  .tentti-pic-frame {
    margin-top: 2rem;
    margin-left: 6rem;
    margin-right: 6rem;
    border-radius: 15px;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
  }
  
  .tentti-pic-frame::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ECF9FF;
    opacity: 0.9;
    border-radius: 15px;
  }
  
  .myTentti {
    height: auto;
    background-color: #ECF9FF;
    margin-top: 0;
  }

  .myTentti h1 {
    text-align: center;
    font-size: 2.3rem;
    padding-top: 6rem;
    margin-top: 0rem;
    color: black;
  }

  .myTentti h1::after {
    content: "";
    display: block;
    border-bottom: 5px solid #fff;
    width: 60%;
    position: relative;
    left: 20%;
    border-radius: 15px; 
  }

  .tentti-container {
    display: flex;
    justify-content: center;
    padding: 10px 10px 3rem;
  }

  .tentti-text1 {
    width: 45%;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 35px;
  }

  .tentti-text2 {
    width: 45%;
    margin-left: 3rem;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 30px;
  }

  .tentti-text1 h2 {
    text-align: center;
  }

  .tentti-text2 h2 {
    text-align: center;
  }

  .tentti-text1,
   .tentti-text2 p {
    text-align: left;
  }

  @media screen and (max-width: 992px) {
    .tentti {
      padding-top: 7rem;
      height: 30rem;
      width: auto;
    }
    
    .tentti-main h1 {
      font-size: 1.5rem;
      margin-bottom: 1.3rem;
    }
    
    .tentti-main h1::after {
      width: 86%;
      position: relative;
      left: 7%;
    }
    
    .tentti-main p {
      font-size: 14px;
      margin-left: 1rem;
      margin-right: 1rem;
    }
    
    .tentti-pic-frame {
      margin-top: 2rem;
      margin-left: 1rem;
      margin-right: 1rem;
      height: 100%;
    }

    .myTentti {
      background-color: #ECF9FF;
      height: auto;
    }

    .myTentti h1 {
      font-size: 1.8rem;
      margin-top: 0rem;
      padding-top: 4.7rem;
      margin-left: 1rem;
      margin-right: 1rem;
    }
  
    .myTentti h1::after {
      width: 80%;
      position: relative;
      left: 10%;
    }
  
    .tentti-container {
      display: block;
      padding: 10px;
      padding-bottom: 3rem;
    }
  
    .tentti-text1 {
      width: auto;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      background-color: #fff;
      border-radius: 15px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      padding: 15px;
      margin-bottom: 2rem;
    }
  
    .tentti-text2 {
      width: auto;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      background-color: #fff;
      border-radius: 15px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      padding: 15px;
    }
  
    .tentti-text1 h2 {
      text-align: center;
      font-size: 20px;
    }
  
    .tentti-text2 h2 {
      text-align: center;
      font-size: 20px;
    }
  
    .tentti-text1,
     .tentti-text2 p {
      text-align: left;
    }
  
  }
