.hamburger {
    cursor: pointer;
    display: none;
    padding-right: 1rem;
}

.hamburger input {
    display: none;
}

.hamburger svg {
    height: 2.1rem;
    transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line {
    fill: none;
    stroke: rgba(0, 229, 204, 1);
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 3;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line-top-bottom {
    stroke-dasharray: 12 63;
}

.hamburger input:checked + svg {
    transform: rotate(-45deg);
}

.hamburger input:checked + svg .line-top-bottom {
    stroke-dasharray: 20 300;
    stroke-dashoffset: -32.42;
}

/* On mobile: show the hamburger */
@media (min-width: 360px) and (max-width: 992px) {
    .hamburger {
        display: block;
    }
}
